<template>
    <div class="support">
      <h1>Support</h1>
      <p>Need help? We're here to assist you.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Support'
  }
  </script>
  
  <style scoped>
  .support {
    padding: 20px;
  }
  </style>